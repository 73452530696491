import { defaultI18nNs } from '@cocoonspace/shared/config/default-i-18-n-ns-config'
import type { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import { HomePage } from '~/domains/home/components/pages/home-page'

export default HomePage

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
	props: {
		...(await serverSideTranslations(locale as string, [
			...defaultI18nNs,
			'cwHome',
		])),
	},
})
