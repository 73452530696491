import type { ComponentProps } from 'react'

import { cn } from '~/lib/utils'

export const SectionWrapper = ({
	children,
	className,
	...props
}: ComponentProps<'div'>) => {
	return (
		<div
			{...props}
			className={cn(
				'container mx-auto flex flex-col gap-6 py-8 md:py-12',
				className,
			)}
		>
			{children}
		</div>
	)
}
